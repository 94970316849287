//dashboard images
import limboImg1 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-1.png'
import limboImg2 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-2.png'
import limboImg3 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-3.png'
import limboImg4 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-4.png'
import limboImg5 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-5.png'
import limboImg6 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-6.png'
import limboImg7 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-7.png'
import limboImg8 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-8.png'
import limboImg9 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-9.png'
import limboImg10 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-10.png'
import limboImg11 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-11.png'
import limboImg12 from '../assets/images/dashboard-limbo-images/dashboard-limbo-product-12.png'
import DashboardBannerImg1 from '../assets/images/dashboard-banner.png'
import LimboDetailSliderImg from '../assets/images/thumb-details-page.png'
import LimboDetailSliderImg1 from '../assets/images/thumb-details-page.png'

import owlImg1 from '../assets/images/product-category/category-car.png'
import owlImg2 from '../assets/images/product-category/category-boats.png'
import owlImg3 from '../assets/images/product-category/category-matresses.png'
import owlImg4 from '../assets/images/product-category/category-outboard.png'
import owlImg5 from '../assets/images/product-category/category-tools.png'

export const data = {
    categories: [
        {
            id: 1,
            label: 'Categories',
            className: 'selected',
        },
        { id: 2, label: 'Appliance' },
        { id: 3, label: 'Cars and Trucks', className: 'active' },
        { id: 4, label: 'Motorcycles' },
        { id: 5, label: 'Tyres' },
        { id: 6, label: 'Accessories' },
        { id: 7, label: 'Electronics' },
        { id: 8, label: 'Furniture' },
        { id: 9, label: 'Sports & Fitness' },
        { id: 10, label: 'Sunglasses' },
        { id: 11, label: 'Water Filtration & Softeners' },
        { id: 12, label: 'More...' },
    ],

    HomeCarouselData: [
        {
            id: 1,
            BannerImg: DashboardBannerImg1,
            h2: 'Deal in',
            h1: 'Sports & Fitness',
        },
        {
            id: 2,
            BannerImg: DashboardBannerImg1,
            h2: 'Deal in',
            h1: 'Sports & Fitness',
        },
    ],

    LimboDetailSlider: [
        {
            id: 1,
            BannerImg: LimboDetailSliderImg,
            ImgTitle: 'Car',
        },
        {
            id: 2,
            BannerImg: LimboDetailSliderImg1,
            ImgTitle: 'Boats & Watercraft',
        },
    ],

    // owlCarouselData: [
    //     {
    //         id: 1,
    //         owlImg: owlImg1,
    //         ImgTitle: 'Car',
    //     },
    //     {
    //         id: 2,
    //         owlImg: owlImg2,
    //         ImgTitle: 'Boats & Watercraft',
    //     },
    //     {
    //         id: 3,
    //         owlImg: owlImg3,
    //         ImgTitle: 'Outboard & Trolling',
    //     },
    //     {
    //         id: 4,
    //         owlImg: owlImg4,
    //         ImgTitle: 'Tools',
    //     },
    //     {
    //         id: 5,
    //         owlImg: owlImg5,
    //         ImgTitle: 'Matresses',
    //     },
    // ],

    DashboardLimboListingData: [
        {
            id: 1,
            category: {
                img: limboImg1,
                title: ' Coffee Machines',
                price: '$ 98',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 2,
            category: {
                img: limboImg2,
                title: ' Honda City',
                price: '$ 45,000',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 3,
            category: {
                img: limboImg3,
                title: ' Motorcycles',
                price: '$ 9,000',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 4,
            category: {
                img: limboImg4,
                title: ' Tyre',
                price: '$ 300',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 5,
            category: {
                img: limboImg5,
                title: ' car accessories',
                price: '$ 1500',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 6,
            category: {
                img: limboImg6,
                title: ' electronics',
                price: '$ 4500',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 7,
            category: {
                img: limboImg7,
                title: ' furniture',
                price: '$ 600',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 8,
            category: {
                img: limboImg8,
                title: ' Sports & Fitness',
                price: '$ 600',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 9,
            category: {
                img: limboImg9,
                title: ' vacuum cleaner ',
                price: '$ 560',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 10,
            category: {
                img: limboImg10,
                title: ' sunglasses',
                price: '$120',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 11,
            category: {
                img: limboImg11,
                title: ' Water Filtration ',
                price: '$ 500',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
        {
            id: 12,
            category: {
                img: limboImg12,
                title: ' Hot Tubs & Pools',
                price: '$ 650',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit Cras non placerat mi.',
                contactInfo: 'See Contact information',
            },
        },
    ],

    CurrentOffers: [
        {
            id: 1,
            date: 'December 15, 2023, 1:00 pm',
            offer: '$28,030',
            vendor: 'Peter Smith',
        },
        {
            id: 2,
            date: 'December 15, 2023, 2:10 pm',
            offer: '$28,400',
            vendor: 'Evelyn R. Davis',
        },
        {
            id: 3,
            date: 'December 15, 2023, 2:15 pm',
            offer: '$28,500',
            vendor: 'Marian J. Suggs',
        },
        {
            id: 4,
            date: 'December 15, 2023, 2:15 pm',
            offer: '$28,600',
            vendor: 'Lamar T. Parise',
        },
    ],

    // "LimboDetails":
    // {
    //     "Make": "BMW",
    //     "Model": "X1",
    //     "Sub-Model": "xDrive28i",
    //     "Consider same year and model with slight variation?": "Yes",
    //     "Trade-in?": "Yes",
    //     "Trade-in vehicle Information (if applicable)": "2008 Honda City, iVTech 50000",
    //     "Shopper's Target Price": "$28,000",
    //     "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat",
    // },

    LimboDetails: [
        {
            id: 1,
            Make: 'BMW',
            createdDate: 'Today',
            type: 'completed',
            chatwithVendor: 'Chat with Vendor',

            images: [
                {
                    id: 1,
                    BannerImg: LimboDetailSliderImg,
                    ImgTitle: 'BMW',
                },
                {
                    id: 2,
                    BannerImg: LimboDetailSliderImg1,
                    ImgTitle: 'BMW',
                },
            ],
            otherInfo: {
                Make: 'BMW',
                Model: 'X1',
                'Sub-Model': 'xDrive28i',
                'Consider same year and model with slight variation?': 'Yes',
                'Trade-in?': 'Yes',
                'Trade-in vehicle Information (if applicable)':
                    '2008 Honda City, iVTech 50000',
                "Shopper's Target Price": '$28,000',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat',
            },
            placeanOffer: 'Place an offer',
            reportLimbo: 'Report this Limbo',
            includedTaxes: '*included all shipping and taxes',
        },
    ],
}

export const constant = {
    reportListing: 'Report this Listing',
    reportLimbo: 'Report this Limbo',
    limbo: 'limbo',
    listing: 'listing',
}

export const reportsColumn = [
    {
        label: 'User Email',
        field: 'userEmail',
    },
    {
        label: 'Product Name',
        field: 'productName',
    },
    {
        label: 'Reason',
        field: 'reason',
    },
    {
        label: 'Type',
        field: 'productType',
    },
    {
        label: 'Action',
        field: 'action',
        width: 200,
    },
]

export const LimbosHead = [
    {
        label: 'Name',
        field: "limboName",
    },
    {
        label: 'Description',
        field: "description",
    },
    {
        label: 'Type',
        field: "limboType",
    },
    {
        label: 'Status',
        field: "status",
    },
    {
        label: 'City',
        field: "city",
    },
    {
        label: 'Action',
        field: "action",
    }
]

export const listingHead = [
    {
        label: 'Name',
        field: "listingName",
    },
    {
        label: 'Type',
        field: "listingType",
        
    },
    {
        label: 'Price',
        field: "price",
    },
    {
        label: 'Status',
        field: "status",
    },
    {
        label: 'Address',
        field: "address",
    },
    {
        label: 'City',
        field: "city",
    },
    {
        label: 'Action',
        field:"action"
    },
]
export const CataegoryHead = [
    {
        label: 'Name',
        field: "limboName",
    },
    {
        label: 'Type',
        field: "limboType",
    },
    {
        label: 'Image',
        field: "limboImage",
    },
    {
        label: 'Status',
        field: "status",
    },
    {
        label: 'Limbo',
        field: "limbo",
    },
    {
        label: 'Listing',
        field: "listing",
    },
    {
        label: 'Action',
        field:"action"
    },
]

export const categoryUpdateFields = [
    {
        label: 'Name',
        field: "limboName",
        type:"text"
    },
    {
        label: 'Type',
        field: "categoryType",
        type:"select"
    },
    {
        label: 'Image',
        field: "categoryImagePath",
        type:"imgUpload"
    },
    {
        label: 'Status',
        field: "status",
        type:"switch"
    },
    {
        label: 'Limbo',
        field: "limbo",
        type:"switch"
    },
    {
        label: 'Listing',
        field: "listing",
        type:"switch"
    }   
]
export const csvCatCreateFields = [
    {
        label: 'Name',
        field: "categoryName",
        type:"text"
    },
    {
        label: 'Type',
        field: "categoryType",
        type:"select"
    },
    {
        label: 'Image',
        field: "categoryImagePath",
        type:"imgUpload"
    },
    {
        label: 'Upload Csv file',
        field: "file",
        type:"csvUpload"
    },
    {
        label: 'Limbo',
        field: "limbo",
        type:"switch"
    },
    {
        label: 'Listing',
        field: "listing",
        type:"switch"
    }   
]
export const csvCatUpdateFields = [
    {
        label: 'Name',
        field: "categoryName",
        type:"text"
    },
    {
        label: 'Type',
        field: "categoryType",
        type:"select"
    },
    {
        label: 'Image',
        field: "categoryImagePath",
        type:"imgUpload"
    },
    {
        label: 'Upload Csv file',
        field: "file",
        type:"csvUpload"
    },
    {
        label: 'Limbo',
        field: "limbo",
        type:"switch"
    },
    {
        label: 'Listing',
        field: "listing",
        type:"switch"
    }   
]
export const csvAppendCreateFields = [
    {
        label: 'Please upload Csv for append',
        field: "file",
        type:"csvUpload"
    }
]

export const subscriptionHeaders = [
    {
        label: 'Name',
        field: "name",
    },
    {
        label: 'Description',
        field: "description",
    },
    {
        label: 'Price',
        field: "price",
    },
    {
        label: 'Status',
        field: "status",
    },
    {
        label: 'Duration Months',
        field: "durationMonths",
    },
    // {
    //     label: 'Features',
    //     field: "features",
    // }
]


export const userCategoriesColumn = [
    {
        label: 'Category',
        field: 'limboName',
    },
    {
        label: 'Field Name',
        field: 'limboFieldName',
    },
    {
        label: 'Value',
        field: 'fieldToAdd',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Import',
        field: 'import',
        width: 200,
    },
    {
        label: 'Delete',
        field: 'delete',
        width: 80,
    },
]