import React, { ReactNode, useState, useEffect } from 'react'
import Moment from 'react-moment'
import './LimboDetails.scss'
import LimboTimer from '../LimboTimer/LimboTimer'
import { Link } from 'react-router-dom'
import ChatIcon from '../../assets/images/chat-icon.svg'
import PlaceAnOfferInput from '../PlaceAnOfferInput/PlaceAnOfferInput'
import editIcon from '../../assets/svg/edit-icon.svg'
import Chat from '../../pages/Chat/Chat'

const LimboDetails = ({
    data,
    curretOffer,
    expireStatus,
    limboStatus,
    getLimboDetailInfo,
}: any) => {
    const [isPlaceOfferClicked, setIsPlaceOfferClicked] = useState(false)
    const userRole = localStorage.getItem('userRole')
    const handlePlaceOfferClick = () => {
        setIsPlaceOfferClicked(true)
    }

    const [showChat, setShowChat] = useState({
        shopperId: '',
        vendorId: '',
        limboId: '',
        show: false,
    })

    const toggleShowChat = (
        shopperId: string,
        vendorId: string,
        limboId: string
    ) => {
        setShowChat((prev) => ({
            shopperId,
            vendorId,
            limboId,
            show: !prev.show,
        }))
    }

    const vendorEmail = localStorage?.getItem('email')?.split('"')?.[1]
    const currentDate = new Date()
    return (
        <div className="time-detail">
            <div className="time-details">
                {data?.endAt > currentDate.toISOString() &&
                    limboStatus != 'closed' && (
                        <LimboTimer endTime={data?.endAt} />
                    )}
                <div className="limbo-created">
                    Limbo Created :
                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                        {data?.createdAt}
                    </Moment>
                </div>
            </div>
            {limboStatus != 'closed' && showChat?.show ? (
                <Chat
                    shopperId={showChat?.shopperId}
                    vendorId={showChat?.vendorId}
                    listingId={showChat?.limboId ? showChat.limboId : ''}
                    closeCallback={() => {
                        setShowChat((prev) => ({ ...prev, show: !prev.show }))
                    }}
                />
            ) : (
                ''
            )}
            {limboStatus != 'closed' &&
            (userRole === '"vendor-service-provider"' ||
                userRole === '"vendor-sub-user"') ? (
                <div
                    className="chat-vendor"
                    onClick={() => {
                        toggleShowChat(
                            data?.createdBy,
                            vendorEmail || '',
                            data?._id
                        )
                    }}
                >
                    <img src={ChatIcon} alt="icon" />
                    <span>
                        <Link to="#">Chat</Link>
                    </span>
                </div>
            ) : (
                ''
            )}
            <div className="limbo-title">
                <div className="info">
                    {/* <h3>{data?.limboName}</h3> */}
                    <p>{data?.description}</p>
                </div>
            </div>

            <div className="limbo--details">
                <div className="limbo-item">
                    <h6>Additional Detail</h6>
                    {data?.limboDetail &&
                        data.limboDetail?.length &&
                        data.limboDetail.length > 0 &&
                        data.limboDetail &&
                        data.limboDetail.map((item: any) => (
                            <div
                                className="flex-inline"
                                key={item?.limboFieldName}
                            >
                                <div className="label">
                                    {item?.limboFieldName}
                                </div>
                                <div className="label_name">
                                    {item?.fieldValue as ReactNode}
                                </div>
                            </div>
                        ))}
                    <div className="flex-inline">
                        <div className="label">Price</div>
                        <div className="label_name">{`${process.env.REACT_APP_CURRENCY_SIGN}$ ${data?.price}`}</div>
                    </div>
                </div>
            </div>
            {(userRole === '"vendor-service-provider"' ||
                userRole === '"vendor-sub-user"') &&
                data?.status != 'closed' &&
                !expireStatus && (
                    <div className="btn-section">
                        <div className="place-an-offer">
                            <div className="place-btn">
                                {curretOffer.length > 0 ? (
                                    <button
                                        className="btn-orange change-offer"
                                        onClick={() => handlePlaceOfferClick()}
                                    >
                                        <img src={editIcon} alt="icon" /> Change
                                        Offer
                                    </button>
                                ) : (
                                    <button
                                        className="btn-orange"
                                        onClick={() => handlePlaceOfferClick()}
                                    >
                                        Place an offer
                                    </button>
                                )}
                            </div>
                            <p>
                                <div className="btn-report">
                                    {data?.[0]?.reportLimbo}
                                </div>
                            </p>
                        </div>

                        {isPlaceOfferClicked && (
                            <PlaceAnOfferInput
                                setShow={setIsPlaceOfferClicked}
                                id={data._id}
                                offerPrice={
                                    curretOffer.length > 0
                                        ? curretOffer[0].offerPrice
                                        : 0
                                }
                                getLimboDetailInfo={getLimboDetailInfo}
                            />
                        )}
                    </div>
                )}
        </div>
    )
}

export default LimboDetails
