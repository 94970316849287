import React, { useEffect, useState } from 'react'
import noImg from '../../../../assets/images/no-image.png'
import CustomModal from '../../../../components/Modal/Modal'
import {
    getMyFavouriteList,
    removeFavourite,
} from '../../../../api/services/favourites.service'
import { getFirst100Words } from '../../../../helper/utils'
import NoDataFound from '../../../../components/NodataFound/NoDataFound'
import { useNavigate } from 'react-router'
import Loader from '../../../../components/Loader/Loader'
import { IoMdEye } from 'react-icons/io'

const Row = ({ item, handleDelete }: any) => {
    const handleRemove = () => {
        handleDelete(item.id)
    }
    const navigate = useNavigate()
    return (
        <div className="listing-list">
            <ul>
                <li>
                    <div className="list-img">
                        {item.images && item.images.length > 0 ? (
                            <img src={item.images[0]} alt="img" />
                        ) : (
                            <img src={noImg} alt="img" />
                        )}
                    </div>
                    <div className="limbo-list-desc">
                        <div className="limbo-content">
                            <h4>{item?.listingName}</h4>
                            <p>{getFirst100Words(item?.description, 10)}</p>
                            {/* <h5>{`$ ${item.price || 0}`}</h5> */}
                        </div>

                        <div className="icons">
                            <IoMdEye
                                size={30}
                                onClick={() => navigate(`/listing/${item?.id}`)}
                            />

                            <CustomModal handleDelete={handleRemove} />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

const Favourites = () => {
    const [favourite, setFavourite] = useState<any>([])
    const dataString = localStorage.getItem('email')
    const email = JSON.parse(dataString || '')
    const [loading, setloading] = useState(false)

    const getFavouriteList = () => {
        setloading(true)
        const payload = {
            userId: email,
        }
        getMyFavouriteList(payload)
            .then((response: any) => setFavourite(response?.data?.list))
            .catch((error: any) => console.log(error))
            .finally(() => setloading(false))
    }

    useEffect(() => {
        getFavouriteList()
    }, [])

    const handleDelete = (listingid: any) => {
        const payload = {
            userid: email,
            listingid: listingid,
        }

        removeFavourite(payload)
            .then((response) => {
                if (response.status === 200) {
                    getFavouriteList()
                }
            })
            .catch((error) => console.log(error))
    }

    if (loading) {
        return (
            <div className="loader__table">
                <Loader />
            </div>
        )
    }
    return (
        <div>
            {favourite?.length > 0 ? (
                favourite.map((item: any, idx: any) => {
                    return (
                        <Row
                            item={item?.listing}
                            key={idx}
                            handleDelete={handleDelete}
                        />
                    )
                })
            ) : (
                <NoDataFound />
            )}
        </div>
    )
}

export default Favourites
