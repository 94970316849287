import React from 'react'
import { Card } from 'react-bootstrap'
import iconAd from '../../../assets/images/icon-ad.svg'
import Ads from '../../../components/Ads/Ads'
import noImg from '../../../assets/images/no-image.png'
import { Link, useNavigate } from 'react-router-dom'
import './landingpagelisting.scss'
import NoDataFound from '../../../components/NodataFound/NoDataFound'
import Loader from '../../../components/Loader/Loader'

const LandingPageListing = ({ ads, data, loadingListing }: any) => {
    const navigate = useNavigate()

    const handleClick = (item: any) => {
        navigate(`/listing/${item?._id}`)
    }

    const cadSign = `${process.env.REACT_APP_CURRENCY_SIGN}`
    const userRole = localStorage.getItem('userRole')

    if (loadingListing) {
        return <Loader />
    }
    return (
        <div className="dashboard-limbo-listing-section">
            <div className="container">
                <div className="row">
                    <div className="limbo-cards-section">
                        <div className="limbo-cards">
                            {!!data && !!data?.length ? (
                                data?.map((item: any, idx: any) => (
                                    <Card
                                        key={idx}
                                        onClick={() => handleClick(item)}
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={
                                                item?.images[0]
                                                    ? item?.images[0]
                                                    : noImg
                                            }
                                            alt={item?.name}
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                <h5>{item?.listingName}</h5>
                                            </Card.Title>
                                            <h5 className="price">{`${cadSign}$ ${
                                                item?.price || 0
                                            }`}</h5>
                                            {/* <Card.Text>
                                                {getFirst100Words(
                                                    item?.description,
                                                    6
                                                )}
                                            </Card.Text> */}
                                            {/* <p className="text-sm">
                                                <Link to="/contact">
                                                    See contact Information
                                                </Link>
                                            </p> */}
                                        </Card.Body>
                                    </Card>
                                ))
                            ) : (
                                <NoDataFound />
                            )}

                            {!!ads && !!ads?.length && ads.length > 0 && (
                                <div className="dashboard-ad-2">
                                    <div className="ads-2">
                                        <Ads slot={'ListingPageBanner'} />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="dashboard-sidebar">
                            <h5>Popular on Price Limbo</h5>
                            {(userRole === `"vendor-service-provider"` ||
                                userRole === `"vendor-sub-user"`) && (
                                <div className="sidenav-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={'/dashboard/createad'}
                                                className="nav-link"
                                            >
                                                <img src={iconAd} alt="icon" />
                                                <span>Wanted ads</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}

                            <div className="side-ads">
                                <Ads slot="Sidebar1stSlot" />
                                <Ads slot="Sidebar2ndSlot" />
                            </div>
                        </div>
                    </div>

                    <div className="get-updates-section">
                        <Ads slot="Footer1stSlot" />
                        <Ads slot="Footer2ndSlot" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPageListing
