import React, { useState } from 'react'
import './PlaceAnOfferInput.scss'
import { createOffers } from '../../api/services/price.service'

import { showToast } from '../../redux/toastSlice'

interface OfferProps {
    setShow: any
    id: any
    offerPrice: number
    getLimboDetailInfo: () => void
}
const PlaceAnOfferInput: React.FC<OfferProps> = ({
    setShow,
    id,
    offerPrice,
    getLimboDetailInfo,
}) => {
    const [quantity, setQuantity] = useState(offerPrice)

    const handleOnChange = (event: any) => {
        setQuantity(event.target.value)
    }

    const handleIncrement = () => {
        setQuantity((prevQuantity) => prevQuantity + 1)
    }

    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity((prevQuantity) => prevQuantity - 1)
        }
    }

    const handlePriceOffer = () => {
        createOffers(id, quantity)
            .then((response) => {
                setShow(false)
                showToast('Offer placed successfully')
                getLimboDetailInfo()
            })
            .catch((error) => {
                setShow(true)
                showToast('An error occurred while placing an offer')
                console.log('error', error)
            })
    }

    return (
        <>
            <div className="quantity">
                <div className="change-offer-btn">
                    <button
                        className="btn minus-btn"
                        type="button"
                        onClick={handleDecrement}
                    >
                        -
                    </button>

                    <input
                        type="number"
                        id="quantity"
                        value={quantity}
                        onChange={handleOnChange}
                    />
                    <button
                        className="btn plus-btn"
                        type="button"
                        onClick={handleIncrement}
                    >
                        +
                    </button>
                </div>
                <button
                    className="btn-update btn-green"
                    onClick={handlePriceOffer}
                >
                    Place offer
                </button>
            </div>
            <p>*included all shipping and taxes</p>
        </>
    )
}

export default PlaceAnOfferInput
